import { css } from "@emotion/core"
import { h2 } from "css/primitives"
import { colors } from "css/theme"
import { graphql } from "gatsby"
import React from "react"
import { BlogPost, BlogPostSticky } from "../components"
import PageLayout from "../components/PageLayout"
import SEO from "../components/seo"
import "src/queries/featured_media"

const wrapper = css`
  padding-bottom: 100px;
  overflow: hidden;
  margin-top: -90px;
  background-color: ${colors.darkBackground};
  @media (max-width: 479px) {
    padding-bottom: 75px;
  }
`

const wrapperTop = css`
  background-image: radial-gradient(
    circle at top,
    ${colors.background} 0%,
    black 100%
  );
  background-repeat: no-repeat;
  padding-top: 182px;
  padding-bottom: 300px;
  padding-left: 1rem;
  padding-right: 1rem;
  @media (max-width: 479px) {
    padding-top: 130px;
    padding-bottom: 270px;
  }
`

const heading = [
  h2,
  css`
    line-height: 1.14;
    font-size: 56px;
    color: white;
    text-align: center;
    margin-bottom: 1.5rem;
  `,
]

const headingText = css`
  color: white;
  opacity: 0.7;
  font-size: 20px;
  line-height: 1.6;
  text-align: center;
  max-width: 670px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 0;
`

const blogListWrapper = css`
  padding-top: 3rem;
`

const postWidth = 336
const postGutterRight = 45

const blogList = css`
  list-style-type: none;
  margin-left: 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  @media (min-width: 992px) and (max-width: 1150px) {
    max-width: ${postWidth * 2 + postGutterRight}px;
  }
  @media (max-width: 991px) {
    max-width: none;
    padding-left: 1rem;
    padding-right: 1rem;
  }
`

const IndexPage = ({ data }) => {
  const posts = data.allWordpressPost.edges
  const sortedPosts = posts.sort((postA, postB) => {
    if (postA.node.sticky) {
      return -1
    } else if (postB.node.sticky) {
      return 1
    }
    return 0
  })
  return (
    <PageLayout options={{ headingBackground: false }}>
      <SEO
        title="Smoke & Mirrors Blog"
        description="Our thoughts, opinions, and ideas on the business of security and the security of businesses"
      />
      <div css={wrapper}>
        <div css={wrapperTop}>
          <h1 css={heading}>Smoke & Mirrors</h1>
          <p css={headingText}>
            Our thoughts, opinions, and ideas on the business of security and
            the security of businesses
          </p>
        </div>
        <div css={blogListWrapper}>
          <ul css={blogList}>
            {sortedPosts.map((document, index) => {
              const post = document.node
              if (index === 0 && post.sticky) {
                return <BlogPostSticky post={post} key={index} />
              }
              return <BlogPost post={post} key={index} />
            })}
          </ul>
        </div>
      </div>
    </PageLayout>
  )
}
export default IndexPage

export const pageQuery = graphql`
  query IndexQuery {
    allWordpressPost {
      edges {
        node {
          id
          slug
          title
          content
          excerpt
          status
          sticky
          wordpress_id
          ...FeaturedMedia
          author {
            name
          }
        }
      }
    }
  }
`
